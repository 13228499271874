<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="card">
        <div style="font-size: 14px; color: #686E74; margin-bottom: 12px;">{{ $t('step1') }}</div>
        <div class="title">
          <div class="caption">{{ $t('bind_two_factor_auth') }}</div>
        </div>
        <div class="content">
          <div style="font-weight: 500; color: #078ABC">
            <i class="fa-solid fa-triangle-exclamation"></i>
            {{ $t('google.step1.note') }}
          </div>
          <div class="mt-2">
            {{ $t('google.step1.content') }}
          </div>
          <div class="content-box mt-4">
            <div style="font-weight: 500" id="textArea">{{ code }}</div>
            <div>
              <button class="btn btn-sm md:btn-md btn-text btn-blue p-0" @click="duplicate">{{ $t('duplicate') }}</button>
              <button class="btn btn-sm md:btn-md btn-text btn-blue p-0 ml-3" @click="download">{{ $t('download') }}</button>
            </div>
          </div>
          <div class="mt-5">
            <button class="btn btn-solid btn-blue" @click="next">{{ $t('next') }}</button>
          </div>
          <div class="mt-4">
            <div class="link link-info">
              <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { backupCode } from '../api/2fa'

export default {
  name: 'setting.google.step1',
  data () {
    return {
      loading: false,
      code: ''
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const ret = await backupCode()
      this.code = ret.data.code
      this.loading = false
    },
    next () {
      this.$router.push({
        name: 'setting.google.step2',
        params: {
          mode: true,
          backupCode: this.code
        }
      })
    },
    duplicate () {
      const content = document.getElementById('textArea').innerHTML
      navigator.clipboard.writeText(content).then(() => {
        alert('copied')
        return true
      }).catch(err => {
        console.log('wrong', err)
      })
    },
    download () {
      const filename = '2fa.txt'
      const text = this.code
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', filename)

      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    }
  }
}
</script>
